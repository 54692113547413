import { action, Action, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy';
import { set as lodashSet } from 'lodash-es';

// types
import {
	JobMetadata,
	SavedJobRequestModel,
	JobsRequestPaginationModel,
	PageSavedJobs,
	UserJobActionModel,
} from 'types/career-services';
import {
	SavedMappedJobs as MappedJobs,
	SavedMappedJob as MappedJob,
	SavedMappedJobUpdateInput as MappedJobUpdateInput,
} from 'types/custom';

import api from './../service';
import { StoreModel } from '../../index';
import { DEFAULT_QUERY_PAYLOAD } from './data';
import { JobType } from 'constants/index';
import { ArrayToObj } from 'utils/mapper';

export interface SavedJobsStore {
	// ********** Data ***************
	data: PageSavedJobs | null;
	queryPayload: SavedJobRequestModel;
	mappedJobs: MappedJobs | null;
	loading: boolean;

	// ********** Actions ***************
	reset: Action<SavedJobsStore>;
	setData: Action<SavedJobsStore, PageSavedJobs>;
	setQueryPayload: Action<SavedJobsStore, SavedJobRequestModel>;
	resetQueryPayload: Action<SavedJobsStore>;
	setSortBy: Action<SavedJobsStore, string>;
	setPagination: Action<SavedJobsStore, JobsRequestPaginationModel>;
	resetPagination: Action<SavedJobsStore>;
	setMappedJobs: Action<SavedJobsStore, MappedJobs>;
	updateMappedJob: Action<SavedJobsStore, MappedJobUpdateInput>;
	deleteMappedJob: Action<SavedJobsStore, { jobId: number }>;
	toggleLoading: Action<SavedJobsStore, boolean>;

	// ********** Thunks ***************
	fetchSavedJobs: Thunk<SavedJobsStore, undefined>;
	fetchJobsMetaAndActions: Thunk<SavedJobsStore, { jobIds: number[] }>;
	// ********** Listeners ***************
	onUserReset: ThunkOn<SavedJobsStore, any, StoreModel>;
	onSetData: ThunkOn<SavedJobsStore, any, StoreModel>;
	onUpdateBookmarkSuccess: ThunkOn<SavedJobsStore, any, StoreModel>;
	onUpdateRelevanceSuccess: ThunkOn<SavedJobsStore, any, StoreModel>;
}

const savedJobs: SavedJobsStore = {
	// ********** Data ***************
	data: null,
	queryPayload: DEFAULT_QUERY_PAYLOAD,
	mappedJobs: null,
	loading: false,
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.data = null;
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
		state.mappedJobs = null;
		state.loading = false;
	}),
	setData: action((state, payload) => {
		state.mappedJobs = null; // to reset mappedJobs to show latest request jobs and not previous requested jobs
		state.data = payload;
	}),
	setQueryPayload: action((state, payload) => {
		state.queryPayload = payload;
	}),
	resetQueryPayload: action((state, payload) => {
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
	}),
	setSortBy: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload.sortBy = payload;
	}),
	setPagination: action((state, payload) => {
		state.queryPayload.pagination = payload;
	}),
	resetPagination: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
	}),
	updateMappedJob: action((state, payload) => {
		const { toChange } = payload;
		const updatedMappedJobs = state.mappedJobs || [];
		const mappedJobIndex = updatedMappedJobs.findIndex(
			(mappedJob: MappedJob) => mappedJob.jobId === payload.jobId,
		);

		if (mappedJobIndex > -1) {
			toChange.forEach(({ key, value }) => {
				lodashSet(updatedMappedJobs[mappedJobIndex], key, value);
			});
			state.mappedJobs = updatedMappedJobs;
		}
	}),
	deleteMappedJob: action((state, payload) => {
		if (state.mappedJobs) {
			state.mappedJobs = state.mappedJobs?.filter(
				(mappedJob) => mappedJob.id !== payload.jobId,
			);
		}
	}),
	setMappedJobs: action((state, payload) => {
		state.mappedJobs = payload;
	}),
	toggleLoading: action((state, payload) => {
		state.loading = payload;
	}),
	// ********** Thunks ***************
	fetchSavedJobs: thunk(async (actions, payload, { getState }) => {
		// Loading Intializing
		actions.toggleLoading(true);

		const savedJobsPayload = getState().queryPayload;
		const response = await api.fetchSavedJobs(savedJobsPayload);
		actions.setData(response as PageSavedJobs);
		actions.setMappedJobs(response.content || []);
		actions.toggleLoading(false);
	}),

	fetchJobsMetaAndActions: thunk(async (actions, payload, { getState }) => {
		const { metaList, userActionList } = await api.fetchMetaAndActions(
			payload.jobIds,
		);

		// check if there are any metadata and actions to be mapped
		if (!metaList.length && !userActionList.length) {
			return;
		}

		const metaMap = ArrayToObj(metaList, 'jobId') as {
			[key: number]: JobMetadata;
		};
		const actionsMap = ArrayToObj(userActionList, 'jobId') as {
			[key: number]: UserJobActionModel;
		};

		const jobs = getState().data?.content || [];
		const mappedJobs = jobs.map((job) => {
			const jobId = job.id as number;
			// return { ...job, meta:metaMap[jobId], userAction:actionsMap[jobId] };
			return { ...job, ...metaMap[jobId], ...actionsMap[jobId] };
		});
		actions.setMappedJobs(mappedJobs);
	}),
	// ********** Listeners ***************
	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),
	onSetData: thunkOn(
		(actions) => actions.setData,
		async (actions, target) => {
			const jobs = target?.payload?.content || [];
			// using as keyword because we know job.id can never be undefined
			const jobIds = jobs.map((job) => job.id) as number[];
			jobIds.length && actions.fetchJobsMetaAndActions({ jobIds });
		},
	),
	onUpdateBookmarkSuccess: thunkOn(
		(actions, storeActions) => storeActions.jobs.updateBookmark.successType,
		async (actions, target) => {
			const { payload } = target;
			if (payload.jobType === JobType.RECOMMENDED) {
				actions.updateMappedJob({
					jobId: payload.jobId,
					toChange: [{ key: 'isBookMarked', value: payload.payload.value }],
				});
			}
		},
	),
	onUpdateRelevanceSuccess: thunkOn(
		(actions, storeActions) => storeActions.jobs.updateRelevance.successType,
		async (actions, target) => {
			const { payload } = target;
			if (payload.jobType === JobType.RECOMMENDED) {
				actions.updateMappedJob({
					jobId: payload.jobId,
					toChange: [
						{ key: 'relevance', value: payload.payload.relevance },
						{
							key: 'relevanceReasonId',
							value: payload.payload.relevanceReason,
						},
					],
				});
			}
		},
	),
};

export default savedJobs;
