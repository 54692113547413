import { getEnv } from '../utils/env';
import {
	LearnIcon,
	LiveIcon,
	JobIcon,
	InterviewPrepIcon,
} from '../components/Icons';
import { DeviceType } from '.';

export const MenuList = [
	{
		name: 'Learn',
		icon: LearnIcon,
		to: getEnv('LEARN_URL'),
		isExternal: true,
		hideOn: null,
	},
	{
		name: 'Live',
		icon: LiveIcon,
		to: getEnv('LIVE_URL'),
		isExternal: true,
		hideOn: null,
	},
	{
		name: 'Jobs',
		icon: JobIcon,
		to: '/jobs',
		isExternal: false,
		hideOn: null,
		trackEvent: {
			name: 'click_transitions',
			data: {
				category: 'Global view interactions'
			}
		}
	},
	{
		name: 'Interview Prep',
		icon: InterviewPrepIcon,
		to: '/preparation',
		isExternal: false,
		hideOn: DeviceType.MOBILE,
		trackEvent: {
			name: 'click_content_hub',
			data: {
				category: 'Global view interactions'
			}
		}
	},
];
