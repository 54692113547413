import { DependencyList, useEffect, useRef } from 'react';

/**
 * This hook unlike useEffect runs only when there is a change in the dependencies 
 * but it does not runs when the components mounts/loads for the 1st time.
 * useEffect = useOnMount + useOnUpdate
 * @param effect - The effect to be executed.
 * @param {DependencyList} deps - DependencyList
 */
const useOnUpdate = (effect: React.EffectCallback, deps: DependencyList) => {
	const isMounted = useRef(false);

	useEffect(() => {
		// check to see if this is the 1st run
		if (!isMounted.current) {
			// if this is the 1st run then setting the mounted to true
			isMounted.current = true;
		} else {
			// executing the effect after the 1st run
			return effect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};

export default useOnUpdate;
