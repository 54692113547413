import { Action, action } from 'easy-peasy';

interface Notification {
	type: 'success' | 'danger' | 'warning' | 'info';
	msg: string;
}

export interface NotificationStore {
	data: Notification | null;
	set: Action<NotificationStore, Notification>;
	reset: Action<NotificationStore>;
}

const notification: NotificationStore = {
	// ********** Data ***************
	data: null,
	// ********** Actions ***************
	set: action((state, payload) => {
		state.data = payload;
	}),
	reset: action((state, payload) => {
		state.data = null;
	}),
};

export default notification;
