import { EffectCallback, useEffect } from 'react';

/**
 * This hooks runs only once when the component is mounted/loaded.
 * So this hook should be used for any of the initializations task to be executed.
 * useEffect = useOnMount + useOnUpdate
 * callback when the component mounts.
 * @param {EffectCallback} effect - The effect to run.
 */
const useOnMount = (effect: EffectCallback) => {
	// eslint-disable-next-line  react-hooks/exhaustive-deps
	useEffect(effect, []);
};
export default useOnMount;
