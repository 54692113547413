export const colorList = [
	{ color: '#F4B51B', backgroundColor: '#163F58' },
	{ color: '#00DCD6', backgroundColor: '#590F2E' },
	{ color: '#F7A01A', backgroundColor: '#030E4E' },
	{ color: '#9FAECE', backgroundColor: '#0F387A' },
	{ color: '#7BD9C0', backgroundColor: '#230070' },
	{ color: '#7887DE', backgroundColor: '#ABDBE6' },
	{ color: '#4F4546', backgroundColor: '#ECC7B2' },
	{ color: '#C698CE', backgroundColor: '#60067E' },
	{ color: '#983340', backgroundColor: '#FCB6B1' },
	{ color: '#BCFDF7', backgroundColor: '#E13D56' },
	{ color: '#B9DF10', backgroundColor: '#390A79' },
	{ color: '#9BECE1', backgroundColor: '#00886F' },
	{ color: '#6FC17C', backgroundColor: '#0E4D1B' },
	{ color: '#FFE2D3', backgroundColor: '#590F2E' },
	{ color: '#ACD0FF', backgroundColor: '#E34923' },
	{ color: '#FC9B4F', backgroundColor: '#101299' },
	{ color: '#0228A7', backgroundColor: '#C2C015' },
	{ color: '#372803', backgroundColor: '#F5B70E' },
	{ color: '#FCE77D', backgroundColor: '#F96166' },
	{ color: '#DF678C', backgroundColor: '#3D155E' },
	{ color: '#EE5455', backgroundColor: '#2A3252' },
	{ color: '#2E3B7D', backgroundColor: '#FAE9EA' },
	{ color: '#FE6F26', backgroundColor: '#2F2F2F' },
	{ color: '#141A45', backgroundColor: '#EC8B5E' },
	{ color: '#FEFEFE', backgroundColor: '#88A9E4' },
	{ color: '#FEE57B', backgroundColor: '#295E2C' },
	{ color: '#EB2187', backgroundColor: '#080A51' },
	{ color: '#E1B144', backgroundColor: '#4A171E' },
	{ color: '#F4A796', backgroundColor: '#348497' },
	{ color: '#A04DF5', backgroundColor: '#E7D045' },
	{ color: '#262223', backgroundColor: '#DDC6B6' },
	{ color: '#C5FAD5', backgroundColor: '#AA96DA' },
	{ color: '#FFFFD2', backgroundColor: '#AA96DA' },
	{ color: '#76B928', backgroundColor: '#F7F7F7' },
	{ color: '#FFE8F5', backgroundColor: '#CB00FF' },
	{ color: '#CC313C', backgroundColor: '#FFC6CC' },
	{ color: '#013DC4', backgroundColor: '#E1D3F4' },
	{ color: '#F9ED4E', backgroundColor: '#533549' },
	{ color: '#96351D', backgroundColor: '#DBB98F' },
	{ color: '#53A57D', backgroundColor: '#FAF7F3' },
];
