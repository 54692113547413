import { colorList } from 'constants/log-colors';

interface LogConfig {
	fileName: string;
	color?: string;
	backgroundColor?: string;
}

enum LogLevel {
	ALL = 'all',
	LOG = 'log',
	LOG_ERROR = 'log-error',
	LOG_SUCCESS = 'log-success',
	LOG_WARNING = 'log-warning',
	ERROR = 'error',
	INFO = 'info',
	WARN = 'warn',
	DEBUG = 'debug',
}

const Style = {
	base: [
		'color: #fff',
		'background-color: #444',
		'padding: 2px 4px',
		'border-radius: 2px',
	],
	error: ['color: #eee', 'background-color: red'],
	success: ['background-color: green'],
	warning: ['background-color: #FF8C00'],
};

const logLevel = localStorage.getItem('logLevel');
let index = 0;

export default class Logger {
	config: LogConfig;

	constructor(config: LogConfig) {
		const { fileName, color, backgroundColor } = config;
		const { color: defaultColor, backgroundColor: defaultBgColor } =
			colorList[index] || colorList[0];

		this.config = {
			fileName,
			color: color || defaultColor,
			backgroundColor: backgroundColor || defaultBgColor,
		};
		index++;
	}

	log(...args: any[]) {
		if ([LogLevel.ALL, LogLevel.LOG].includes(logLevel as LogLevel)) {
			const customStyle = [];
			this.config.color && customStyle.push(`color: ${this.config.color}`);
			this.config.backgroundColor &&
				customStyle.push(`background-color: ${this.config.backgroundColor}`);

			let style = Style.base.join(';') + ';' + customStyle.join(';');
			const consoleParams = [`%c${this.config.fileName}`, style, ...args];
			console.log(...consoleParams);
		}
	}

	logSuccess(...args: any[]) {
		if ([LogLevel.ALL, LogLevel.LOG_SUCCESS].includes(logLevel as LogLevel)) {
			let style = Style.base.join(';') + ';' + Style.success.join(';');
			const consoleParams = [`%c${this.config.fileName}`, style, ...args];
			console.log(...consoleParams);
		}
	}

	logError(...args: any[]) {
		if ([LogLevel.ALL, LogLevel.LOG_ERROR].includes(logLevel as LogLevel)) {
			let style = Style.base.join(';') + ';' + Style.error.join(';');
			const consoleParams = [`%c${this.config.fileName}`, style, ...args];
			console.log(...consoleParams);
		}
	}

	logWarning(...args: any[]) {
		if ([LogLevel.ALL, LogLevel.LOG_WARNING].includes(logLevel as LogLevel)) {
			let style = Style.base.join(';') + ';' + Style.warning.join(';');
			const consoleParams = [`%c${this.config.fileName}`, style, ...args];
			console.log(...consoleParams);
		}
	}

	error(...args: any[]) {
		if ([LogLevel.ALL, LogLevel.ERROR].includes(logLevel as LogLevel)) {
			console.error(...args);
		}
	}

	warn(...args: any[]) {
		if ([LogLevel.ALL, LogLevel.WARN].includes(logLevel as LogLevel)) {
			console.warn(...args);
		}
	}

	info(...args: any[]) {
		if ([LogLevel.ALL, LogLevel.WARN].includes(logLevel as LogLevel)) {
			console.info(...args);
		}
	}

	debug(...args: any[]) {
		if ([LogLevel.ALL, LogLevel.DEBUG].includes(logLevel as LogLevel)) {
			console.debug(...args);
		}
	}
}
