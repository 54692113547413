import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Flex from '../Flex';
import { Color } from '../../constants';
import styles from './index.module.scss';
interface SpinnerProps {
	overlay?: boolean;
}
const antIcon = (
	<LoadingOutlined style={{ fontSize: 36 }} spin color={Color.PRIMARY} />
);
const Spinner: React.FC<SpinnerProps> = (props) => {
	const { overlay = false } = props;
	return (
		<Flex
			height="100vh"
			width="100vw"
			container
			justifyContent="center"
			alignItems="center"
			className={overlay ? styles.container : undefined}
		>
			<Spin indicator={antIcon} />
		</Flex>
	);
};
export default Spinner;
