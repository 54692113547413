import { action, Action, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy';
import { set as lodashSet } from 'lodash-es';
// types
import {
	JobMetadata,
	JobsRequestFilterSortModel,
	JobsRequestPaginationModel,
	PageJobApplications,
	JobApplicationStatus,
} from 'types/career-services';
import {
	Filters,
	AppliedMappedJobs as MappedJobs,
	AppliedMappedJob as MappedJob,
	AppliedMappedJobUpdateInput as MappedJobUpdateInput,
} from 'types/custom';

import api from './../service';
import { StoreModel } from '../../index';
import { DEFAULT_QUERY_PAYLOAD, FILTERS_DATA } from './data';
import { ArrayToObj } from 'utils/mapper';

export interface AppliedJobsStore {
	// ********** Data ***************
	data: PageJobApplications | null;
	filterSchemas: Filters;
	queryPayload: JobsRequestFilterSortModel;
	mappedJobs: MappedJobs | null;
	loading: boolean;

	// ********** Actions ***************
	reset: Action<AppliedJobsStore>;
	setData: Action<AppliedJobsStore, PageJobApplications>;
	setQueryPayload: Action<AppliedJobsStore, JobsRequestFilterSortModel>;
	resetQueryPayload: Action<AppliedJobsStore>;
	updateFilters: Action<AppliedJobsStore, { key: string; value: any }>;
	setMappedJobs: Action<AppliedJobsStore, MappedJobs>;
	setSortBy: Action<AppliedJobsStore, string>;
	setPagination: Action<AppliedJobsStore, JobsRequestPaginationModel>;
	resetPagination: Action<AppliedJobsStore>;
	toggleLoading: Action<AppliedJobsStore, boolean>;
	updateMappedJob: Action<AppliedJobsStore, MappedJobUpdateInput>;
	deleteMappedJob: Action<AppliedJobsStore, { jobId: number }>;

	// ********** Thunks ***************
	fetchAppliedJobs: Thunk<AppliedJobsStore, undefined>;
	fetchJobsMetaAndStatuses: Thunk<
		AppliedJobsStore,
		{ jobIds: number[]; applicationIds: number[] }
	>;

	// ********** Listeners ***************
	onUserReset: ThunkOn<AppliedJobsStore, any, StoreModel>;
	onSetData: ThunkOn<AppliedJobsStore, any, StoreModel>;
}

const appliedJobs: AppliedJobsStore = {
	// ********** Data ***************
	data: null,
	filterSchemas: FILTERS_DATA,
	queryPayload: DEFAULT_QUERY_PAYLOAD,
	mappedJobs: null,
	loading: false,
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.data = null;
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
		state.mappedJobs = null;
		state.loading = false;
	}),
	setData: action((state, payload) => {
		state.mappedJobs = null; // to reset mappedJobs to show latest request jobs and not previous requested jobs
		state.data = payload;
	}),
	setQueryPayload: action((state, payload) => {
		state.queryPayload = payload;
	}),
	resetQueryPayload: action((state, payload) => {
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
	}),
	updateFilters: action((state, payload) => {
		const { key, value } = payload;
		let filters: any = state.queryPayload.filters;
		lodashSet(filters, key, value);
		const pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload = { ...state.queryPayload, pagination, filters };
	}),
	setSortBy: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload.sortBy = payload;
	}),
	setPagination: action((state, payload) => {
		state.queryPayload.pagination = payload;
	}),
	resetPagination: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
	}),
	updateMappedJob: action((state, payload) => {
		const { toChange } = payload;
		const updatedMappedJobs = state.mappedJobs || [];
		const mappedJobIndex = updatedMappedJobs.findIndex(
			(mappedJob: MappedJob) => mappedJob.jobId === payload.jobId,
		);

		if (mappedJobIndex > -1) {
			toChange.forEach(({ key, value }) => {
				lodashSet(updatedMappedJobs[mappedJobIndex], key, value);
			});
			state.mappedJobs = updatedMappedJobs;
		}
	}),
	deleteMappedJob: action((state, payload) => {
		if (state.mappedJobs) {
			state.mappedJobs = state.mappedJobs?.filter(
				(mappedJob: MappedJob) => mappedJob.id !== payload.jobId,
			);
		}
	}),
	setMappedJobs: action((state, payload) => {
		state.mappedJobs = payload;
	}),
	toggleLoading: action((state, payload) => {
		state.loading = payload;
	}),
	// ********** Thunks ***************
	fetchAppliedJobs: thunk(async (actions, payload, { getState }) => {
		// Loading Intializing
		actions.toggleLoading(true);

		const appliedJobsPayload = getState().queryPayload;
		const response = await api.fetchAppliedJobs(appliedJobsPayload);
		actions.setData(response);
		actions.setMappedJobs(response.content || []);
		actions.toggleLoading(false);
	}),

	fetchJobsMetaAndStatuses: thunk(async (actions, payload, { getState }) => {
		const { metaList, statusList } = await api.fetchMetaAndStatuses(
			payload.jobIds,
			payload.applicationIds,
		);

		// check if there are any metadata and actions to be mapped
		if (!metaList.length && !statusList.length) {
			return;
		}

		const metaMap = ArrayToObj(metaList, 'jobId') as {
			[key: number]: JobMetadata;
		};
		const statusesMap = ArrayToObj(statusList, 'applicationId') as {
			[key: number]: JobApplicationStatus;
		};

		const jobs = getState().data?.content || [];
		const mappedJobs = jobs.map((job) => {
			const jobId = job.id as number;
			const applicationId = job.applicationId as number;
			// return { ...job, meta:metaMap[jobId], applicationStatus:statusesMap[applicationId] };
			return { ...job, ...metaMap[jobId], ...statusesMap[applicationId] };
		});
		actions.setMappedJobs(mappedJobs);
	}),
	// ********** Listeners ***************
	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),
	onSetData: thunkOn(
		(actions) => actions.setData,
		async (actions, target) => {
			const jobs = target?.payload?.content || [];
			// using as keyword because we know job.id can never be undefined
			const jobIds = jobs.map((job) => job.id) as number[];
			const applicationIds = jobs.map((job) => job.applicationId) as number[];
			(jobIds.length || applicationIds.length) &&
				actions.fetchJobsMetaAndStatuses({ jobIds, applicationIds });
		},
	),
};

export default appliedJobs;
