export enum RelevanceType {
	'DISLIKE' = -1,
	'UNSELECTED' = 0,
	'LIKE' = 1,
}

export const jobDislikeOptions = Object.freeze([
	{ label: 'Experience not relevant', value: 5 },
	{ label: "Doesn't match the skills", value: 6 },
	{ label: 'Location not preferred', value: 7 },
	{ label: 'Company not desirable', value: 8 },
	// { label: "I'm overqualified", value: 9 }
]);

export const jobLikeOptions = Object.freeze([
	{ label: 'Job matched my profile', value: 1 },
	{ label: 'Good company', value: 2 },
	{ label: 'Preferred location', value: 3 },
	{ label: 'Relevant experience', value: 4 },
]);
