import { useEffect } from 'react';

/**
 * This hooks runs only once when the component is destroyed/unmounted.
 * So this hook should be used for any of the cleanup task to be executed.
 * callback when the component unmounts.
 * @param {callback} effect - The effect to run.
 */
const useOnUnmount = (callback: () => void) => {
	useEffect(() => {
		return callback;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
export default useOnUnmount;
